<template>
<transition name="fadePage" mode="out-in">
<div class="exchangePower">
  <Top-Nav></Top-Nav>
  <div class="common_title">{{$t('menu.dhsl')}}</div>
  <div class="exchange_box">
    <div class="section_one">
      <div class="title"><span>{{$t('menu.dhsl_num')}}</span><i>{{$t('menu.remainingAmount')}}{{remainingAmount}}</i></div>
      <div><input type="number" :min="1" placeholder="0" v-model="exchangeNum" @input="exchangeNum=exchangeNum.replace(/\D/g,'')"></div>
      
      <div class="tip" style="color:red" v-if="!isAllowanceZeed || !isAllowanceUsdt">{{$t('menu.authorizedFirst')}}</div>
      <div class="tip" v-if="swapMaxNum>0" >{{$t('menu.convertibleQuantity')}} {{swapMinNum}}U - {{swapMaxNum | integerFilter}}U</div>
      <div class="tip" v-if="swapMaxNum==0" >{{$t('menu.convertibleQuantity')}} {{swapMaxNum}}U</div>
    </div>
    <div class="section_two">
      <div class="title"><span>{{$t('menu.quantityUsed')}}</span></div>
      <div class="content">
        <div class="sec">
          <div class="name_ex"><img src="../../../public/images/img-ZEED-ex.png" alt=""><span>ZEED</span></div>
          <div class="num_la">{{needZeedNum | numFormat2}}</div>
          <div>{{$t('menu.balance')}}<i class="font_Bebas">{{zeedBalance}}</i></div>
          <div class="get_btn" v-if="isZeed==0 && !isAllowanceZeed"><button :disabled="zeedBtn" @click="approveZeed" :class="{disabled_btn:zeedBtn}">{{$t('menu.grantAuthorization')}} ZEED</button></div>
          <div class="get_btn_ed" v-if="isZeed==1"><button>{{$t('menu.authorized')}}ZEED</button></div>
        </div>
        <div class="sec">
          <div class="name_ex"><img src="../../../public/images/img-ZEED-usdt.png" alt=""><span>USDT</span></div>
          <div class="num_la">{{needUsdtNum | numFormat2}}</div>
          <div>{{$t('menu.balance')}}<i class="font_Bebas">{{usdtBalance}}</i></div>
          <div class="get_btn" v-if="isUsdt==0 && !isAllowanceUsdt"><button @click="approveUsdt" :disabled="usdtBtn" :class="{disabled_btn:usdtBtn}">{{$t('menu.grantAuthorization')}} USDT</button></div>
          <div class="get_btn_ed" v-if="isUsdt==1"><button>{{$t('menu.authorized')}}USDT</button></div>
        </div>
      </div>
    </div>
    <div class="section_three">
      <div class="title"><span>{{$t('menu.availableComPower')}}</span><i>{{canGetPower}}</i></div>
    </div>
    <div class="sec_tip">{{$t('menu.grantAuthorizationTip')}}</div>
    <div class="section_four">
      <button :disabled="exchangeBtn" :class="{blue_bac_btn:true,disabled_btn:exchangeBtn}" @click="exchangeP">{{$t('menu.exchange')}}</button>
      <!-- <button :disabled="exchangeBtn" :class="{blue_bac_btn:true,disabled_a:isDisabl_a}" @click="exchangeP">{{$t('menu.exchange')}}</button> -->
    </div>
  </div>
</div>
</transition>
</template>
<script>
import '../../style/base.css';
import '../../style/exchangePower.css';
import TopNav from '../../components/common/TopNav.vue';
// import { powerInfo } from "../../apis/exchangePower";

const starPool = require('../../utils/starPool')

/**
 * 240-（4800-我的算力/3）
 * 4800-我的算力/3 < 240，就不能申购了
 */
export default {
  name: 'ExchangePower',
  components:{TopNav},
  data() {
    return {
      exchangeNum:0, //  申购数量(*申购数量限制整数
      remainingAmount:0, // 申购池可申购金额
      zeedBalance:0, // ZEED余额
      usdtBalance:0, // USDT余额
      seedTokenPrice:0,// SeedToken价格
      needZeedNum:0,//需要使用ZEED数量
      needUsdtNum:0,//需要使用USDT数量
      canGetPower:0,//可获得算力
      // isDisabl_a:false,//禁用a标签
      exchangeBtn:false, //是否禁用申购算力按钮
      zeedBtn:false,  //是否禁用授权zeed按钮
      usdtBtn:false,  //是否禁用授权usdt按钮
      isZeed:0, //授权zeed是否成功，成功为1,失败为0
      isUsdt:0, //授权usdt是否成功，成功为1,失败为0
      swapMinNum: 240,//算力申购最小数量U
      swapMaxNum: 4800,//算力申购最大数量U = （4800-我的算力/3）
      myComputingPower:0,//我的算例
      isAllowanceUsdt:true,//是否授权过Usdt
      isAllowanceZeed:true,//是否授权过Zeed
    }
  },
  mounted(){
    const that = this
    // const address='0x8d90D9D868729d54bA180bD77A7De2521219059C';
    // localStorage.setItem('address', address)

    // 获取申购池可申购金额
    starPool.canSwapAmount().then(res => {
      that.remainingAmount = res
    })

    // 获取USDT余额
    starPool.getUsdtBalance().then(res => {
      that.usdtBalance = res
    })
    // 获取ZEED余额
    starPool.getSeedTokenBalance().then(res=>{
      that.zeedBalance = res
    })
    // 获取SeedToken价格
    starPool.getSeedPrice().then(res=>{
      that.seedTokenPrice = res
    })
    /** 获取（我的算力） */
    this.getMyComputingPower()
    
    // this.getInfoData();

    // 检测是否授权USDT
    starPool.allowanceUsdt().then(res=>{
      that.isAllowanceUsdt = res>0?true:false
    })
    // 检测是否授权ZEED
    starPool.allowanceZeed().then(res=>{
      that.isAllowanceZeed = res>0?true:false
    })
  },
  watch:{
    // 监听申购数量变化
    exchangeNum(newVal,oldVal){
      console.log(newVal,oldVal)
      let needUsdtNum = newVal / 2; // USDT = 申购数量的50%
      let that = this
      that.needUsdtNum = needUsdtNum
      let needZeedNum = needUsdtNum/ that.seedTokenPrice // ZEED = USDT * SeedToken价格
      that.needZeedNum = needZeedNum
      let canGetPower = newVal*3 // 申购数量*3
      that.canGetPower = canGetPower

    }
  },
  methods:{
    // 获取（我的算力）
    getMyComputingPower(){
      let that = this
      // 用户挖矿数据
      starPool.userMintInfo().then(res=>{
        that.myComputingPower = res._pow // 用户算力

        let swapMaxNum = 4800 - starPool.amountConversion(that.myComputingPower)/3 // 算力申购最大数量U = （4800-我的算力/3）
        //  4800-我的算力/3 < 240，就不能申购了
        if(swapMaxNum < that.swapMinNum){
          that.swapMaxNum = 0
          that.swapMinNum = 0
        }else{
          that.swapMaxNum = swapMaxNum
        }
      
      })
    },
    exchangeP(){
      let that = this
      // 没有绑定关系的情况下，不能申购算力. 2022-05-22
      that.exchangeBtn = true;
      starPool.getRef().then(res=>{
        console.log('推荐人',res)
        if(res.ref === '0x0000000000000000000000000000000000000000'){
           return this.$toast(this.$t('menu.noRecommenderBound'));
        }

        if(that.needZeedNum > that.zeedBalance){
          return this.$toast("ZEED"+this.$t('menu.creditLow'));
        }
        if(that.needUsdtNum > that.usdtBalance){
          return this.$toast("USDT"+this.$t('menu.creditLow'));
        }

        // 获取申购池可申购金额
        starPool.canSwapAmount().then(res => {
          that.remainingAmount = res

          if(that.exchangeNum>that.remainingAmount){
            return this.$toast(this.$t('menu.insufficientAmount'));
          }
          // this.$toast("申购开始");
          // 算力申购
          let _amount = this.exchangeNum
          console.log(_amount)
          if(_amount==0 || _amount<that.swapMinNum){
            return this.$toast(this.$t('menu.exchangeQuantityTip') + that.swapMinNum+ "U");
          }
          if(_amount>that.swapMaxNum){
            return this.$toast(this.$t('menu.exchangeQuantityTip2')+ that.swapMaxNum + "U");
          }
          
          // that.isDisabl_a = true
          starPool.swap(_amount).then(res=>{
            console.log(res)
            // that.isDisabl_a = false
            // that.exchangeBtn = false;
          })

        })

      })
    },
    // 授权Zeed
    approveZeed(){
      let that = this
      // that.isDisabl_a = true
      that.zeedBtn = true;
      starPool.approveZeed().then(res => {
        console.log(res)
        // that.zeedBtn = true;
        //this.isZeed = 1;//授权成功后进行此操作
        console.log(typeof res)
        if(typeof res !=='undefined'){
          that.isAllowanceZeed = true
        }
      })
    },
    // 授权USDT
    approveUsdt(){
      let that = this
      // that.isDisabl_a = true
      that.usdtBtn = true;
      starPool.approveUsdt().then(res => {
        console.log(res)
        // that.isDisabl_a = false
        //this.isUsdt = 1;//授权成功后进行此操作
        if(typeof res !=='undefined'){
          that.isAllowanceUsdt = true
        }
      })
    }

    // getInfoData(){
    //   const param = {};
    //   powerInfo(param).then(response => {
    //     if (response.code === 1) {
    //       if (response.data) {
            
            
    //       }
    //     } else {
          
    //     }
    //   });
    // }
  }
}
</script>
<style scoped>

</style>